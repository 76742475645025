@import "../variable.scss";

.itw-gamecard {
  .itw-gamecard-container {
    width: inherit;
    height: 300px;
    position: absolute;
  }

  .itw-gamecard-author-container {
    position: absolute;
    right: 10px;
    bottom: 130px;
    width: auto;
    height: 44px;
    overflow: auto;
    border-radius: 22px; // For height 40px
    background-color: $secondaryColor;
    cursor: pointer;

    .itw-gamecard-author-name {
      float: left;
      line-height: 44px;
      height: 100%;
      max-width: 200px;
      padding-left: 1rem;
      padding-right: 0.5rem;
      transition: width 0.2s, padding-left 0.2s, padding-right 0.2s;
    }

    .itw-gamecard-author-name-hidden {
      float: left;
      line-height: 44px;
      height: 100%;
      width: 0;
      padding-left: 0rem;
      padding-right: 0rem;
      transition: width 0.2s, padding-left 0.2s, padding-right 0.2s;
      font-size: 0;
    }

    .itw-gamecard-author-avatar {
      margin: 2px;
      float: right;
    }
  }

  .itw-gamecard-media-thumbnail {
    width: inherit;
    height: 180px;
    object-fit: cover;
  }

  .itw-gamecard-info {
    position: absolute;
    top: 180px;
    width: inherit;
    height: 120px;
  }

  .itw-gamecard-info-text {
    height: 60px;
  }

  .itw-gamecard-info-title {
    width: inherit;
    text-align: left;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .itw-gamecard-info-desc {
    width: inherit;
    text-align: left;
    margin-left: 5px;
    font-size: 10px;
  }

  .itw-gamecard-info-tags {
    height: 60px;
    .itw-gamecard-info-tag {
      margin: 2px 2px 1px 1px;
      box-shadow: 0 2px 3px 0 #ddd;
    }
  }
}

p.itw-gamecard-media-author-id {
  margin-left: 1rem;
  margin-right: 1rem;
}
