.itw-gamescreen-content-hidden {
  display: none;
}

.itw-gamescreen-content {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  justify-content: center;
  display: flex;
}
