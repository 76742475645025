.itw-gamegrid {
  overflow-x: hidden;
  overflow-y: hidden;

  .itw-gamegrid-gamelist {
    min-height: 100vh;
    padding-top: 1rem;
    overflow-x: hidden;
    overflow-y: hidden;

    .itw-gamegrid-responsive-grid {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(auto-fill, 350px);
      grid-auto-flow: dense;
      justify-content: center;
    }

    .itw-gamegrid-responsive-grid-item {
      width: 350px;
      height: 300px;
      box-shadow: 2px 2px 2px 1px #ddd;
    }

    .itw-gamegrid-label-moregame {
      padding-top: 2rem;
    }
  }
}
