.itw-gamelaunchpad {
  width: 100%;
  height: 100%;
  z-index: 1200;
  border-width: 0;
}

.itw-gamelaunchpad-hidden {
  display: none;
}

button.itw-gamelaunchpad-back {
  z-index: 1201;
  position: absolute;
  left: calc(50% - 20px);
  bottom: 1rem;
}

button.itw-gamelaunchpad-back-hidden {
  display: none;
}

// Make the square icon floating up.
// Need to be added to extra <div> next to the icon <span> in icon button or ButtonBase
// position: absolute;
// width: 12px;
// height: 12px;
// box-shadow: 1.5px 1.5px 1.5px #999;
