.itw-gameinfo-hidden {
  display: none;
}

.itw-gameinfo {
  max-width: 1000px;
  width: 100%;
}

.itw-gameinfo-detail {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  color: #000;
}
