@import "../variable.scss";

.itw-video-container-root {
  box-shadow: 2px 2px 2px 1px #ddd;
}

.itw-video-container {
  padding-bottom: 56.25%;
  position: relative;

  .itw-video-frame {
    background-color: $secondaryColor;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.itw-video-container-hidden {
  display: none;
}

.itw-video-skeleton-hidden {
  display: none;
}

.itw-video-skeleton-group {
  width: 80%;
  max-width: 350px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -49%);
}

.itw-video-platforms {
  justify-content: flex-end;
  display: flex;
  background-color: white;

  .Mui-checked > .MuiIconButton-label {
    background-color: $secondaryColor;
  }
}
